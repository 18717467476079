/*
 Generated by typeshare 1.7.0
*/

export type LocalizedStringDto = Record<string, string>;

export interface AppErrorDto {
  key: string;
}

export interface FenixConfigDto {
  baseUrl: string;
  clientId: string;
  redirectUrl: string;
}

export interface AppConfigDto {
  fenix: FenixConfigDto;
  isSetup: boolean;
}

export interface LoginDto {
  code: string;
}

export interface DegreeEntryDto {
  degreeId: string;
  curricularYear: number;
  nominationElegible: boolean;
}

export interface UserDto {
  username: string;
  name: string;
  displayName: string;
  degreeEntries: DegreeEntryDto[];
}

export interface AuthDto {
  user: UserDto;
  isAdmin: boolean;
}

export interface AdminDto {
  username: string;
  dateAdded: string;
}

export interface AddAdminDto {
  username: string;
}

export interface DegreeDto {
  id: string;
  acronym: string;
  name: LocalizedStringDto;
  degreeType: LocalizedStringDto;
}

export interface DateRangeDto {
  start: string;
  end: string;
}

export enum ElectionStatusDto {
  NotStarted = 'NOT_STARTED',
  Candidacy = 'CANDIDACY',
  Processing = 'PROCESSING',
  Voting = 'VOTING',
  Ended = 'ENDED',
}

export interface NominationDto {
  username: string;
  displayName: string;
  valid?: boolean;
  votes?: number;
}

export interface ElectionDto {
  id: number;
  academicYear: string;
  degree?: DegreeDto;
  curricularYear?: number;
  candidacyPeriod?: DateRangeDto;
  votingPeriod: DateRangeDto;
  round: number;
  status: ElectionStatusDto;
  hasNominated?: boolean;
  hasVoted?: boolean;
  nominations?: NominationDto[];
  totalVotes?: number;
}

export interface DegreeElectionsDto {
  degree: DegreeDto;
  elections: ElectionDto[];
}

export interface SearchPersonDto {
  election: number;
  query: string;
}

export interface SignedPersonSearchResultDto {
  username: string;
  displayName: string;
  signature: string;
}

export interface BulkCreateElectionsDegreesDto {
  degreeId: string;
  curricularYear?: number;
}

export interface BulkCreateElectionsDto {
  candidacyPeriod?: DateRangeDto;
  votingPeriod: DateRangeDto;
  round: number;
  degrees: BulkCreateElectionsDegreesDto[];
}

export interface VoteOptionDto {
  username: string;
  displayName: string;
}

export interface CastVoteDto {
  username?: string;
}

export interface ElectionWithUnverifiedNominationsDto {
  id: number;
  degree?: DegreeDto;
  curricularYear?: number;
  round: number;
  nominations: NominationDto[];
}

export interface EditNominationDto {
  username: string;
  displayName?: string;
  valid?: boolean;
}

export interface BulkAddUserDegreeOverrideDto {
  degreeId: string;
  curricularYear: number;
  usernames: string[];
}

export interface BulkDeleteUserDegreeOverrideDto {
  degreeId: string;
  usernames: string[];
}

export interface UserDegreeOverrideDto {
  username: string;
  curricularYear: number;
}

export interface DegreeWithUserOverridesDto {
  degree?: DegreeDto;
  users: UserDegreeOverrideDto[];
}
